// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'id'
  }, {
    prop: 'user'
  }, {
    prop: 'reward_name'
  }, {
    prop: 'phone_number'
  }, {
    prop: 'update'
  }, {
    prop: 'point'
  }, {
    prop: 'remark'
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }]
}
